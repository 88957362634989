import { Box } from '@mui/material';
import React from 'react';

export interface ImageMissingProps {
  text: string;
}

export const ImageMissing = ({ text }: ImageMissingProps) => (
  <Box
    sx={{
      p: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      height: '100%',
      color: '#ccc',
    }}
  >
    {text}
  </Box>
);
