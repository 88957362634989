import { Button, TextField, Typography } from '@mui/material';
import React, { ChangeEvent, useState } from 'react';
import * as styles from './article-info.module.scss';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ContentfulArticlePage, ContentfulEcomCommon } from '../../contentful';
import { useAppDispatch } from '../../hooks';
import { updateApiUserCart } from '../../store/slices';
import { CartItem } from '../../store/interfaces';
import { graphql } from 'gatsby';
import { connect } from 'react-redux';
import { RootState } from '../../store';
import { ArticleFavouriteToggle } from '../article-favourite-toggle';

const mapStateToProps = (state: RootState) => {
  return { favouritesFromStore: state.shop.favourites };
};

interface ArticleInfoProps {
  favouritesFromStore: string[];
  articlePage: ContentfulArticlePage;
  ecomCommon: ContentfulEcomCommon;
}

const ArticleInfo = ({
  favouritesFromStore,
  articlePage,
  ecomCommon,
}: ArticleInfoProps) => {
  const dispatch = useAppDispatch();

  const addToCartActionCreator = () => {
    if (isNaN(articleQuantity)) return;

    const cartItem: CartItem = {
      articleNumber: articlePage.articleNumber,
      articleTitle: articlePage.title,
      quantity: articleQuantity,
      articleQuantityAndAreaExplanation:
        articlePage.quantityAndAreaExplanation ?? '',
      articleSalesUnit: articlePage.salesUnit ?? '',
    };
    dispatch(updateApiUserCart({ cartItem, operation: 'ADD' }));
    setArticleQuantity(1);
  };

  const [articleQuantity, setArticleQuantity] = useState<number>(1);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const quantity = parseInt(event.target.value, 10);
    if (!isNaN(quantity) && quantity >= 1) {
      setArticleQuantity(quantity);
    } else {
      setArticleQuantity(1);
    }
  };

  const decreaseArticleQuantity = () => {
    if (articleQuantity <= 1) return;
    setArticleQuantity(articleQuantity - 1);
  };

  const increaseArticleQuantity = () => {
    setArticleQuantity(articleQuantity + 1);
  };

  const articleIsFavourite: boolean = favouritesFromStore.includes(
    articlePage.articleNumber,
  );

  return (
    <>
      <div className={styles.headingWrapper}>
        <Typography variant="h1" component="h1" sx={{ mb: 2 }}>
          {articlePage.title}
        </Typography>
        <div className={styles.favourite}>
          <ArticleFavouriteToggle
            articleNumber={articlePage.articleNumber}
            favourite={articleIsFavourite}
          />
        </div>
      </div>
      <ul>
        {articlePage.features?.map((feature, index) => (
          <li key={feature.replaceAll(' ', '') + index}>{feature}</li>
        ))}
      </ul>
      <Typography variant="body2" sx={{ mb: 2 }}>
        {ecomCommon.articleNumber}: {articlePage.articleNumber}
      </Typography>

      <Typography variant="body2" sx={{ mb: 0.5 }}>
        {ecomCommon.quantity}:
      </Typography>

      <div className={styles.quantityWrapper}>
        <Button
          variant="contained"
          aria-label="Subtract from quantity"
          className={`${styles.quantityButton} ${styles.quantityButtonSubtract}`}
          sx={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          onClick={decreaseArticleQuantity}
        >
          <RemoveIcon />
        </Button>
        <TextField
          type="number"
          size="small"
          autoComplete="off"
          required
          value={articleQuantity}
          onChange={onChange}
          InputProps={{
            inputProps: {
              min: 1,
              className: styles.quantity,
            },
          }}
        />
        <Button
          variant="contained"
          aria-label="Add to quantity"
          className={`${styles.quantityButton} ${styles.quantityButtonAdd}`}
          sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
          onClick={increaseArticleQuantity}
        >
          <AddIcon />
        </Button>
        <div className={styles.salesUnit}>{articlePage.salesUnit}</div>
      </div>
      <Typography variant="body2" sx={{ mb: 2.5 }}>
        {articlePage.quantityAndAreaExplanation}
      </Typography>
      <Button
        variant="contained"
        sx={{ mb: 4 }}
        size="large"
        onClick={addToCartActionCreator}
      >
        {ecomCommon.addToCart}
      </Button>
    </>
  );
};

export const query = graphql`
  fragment ArticlePage on ContentfulPageArticle {
    image {
      gatsbyImage(layout: CONSTRAINED, width: 800)
    }
    articleNumber
    features
    listDescription
    salesUnit
    slug
    title
    quantityAndAreaExplanation
  }
`;

export default connect(mapStateToProps)(ArticleInfo);
