import { Grid } from '@mui/material';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { Helmet } from 'react-helmet';
import ArticleInfo from '../components/article-info/article-info';
import { ImageMissing } from '../components/image-missing/image-missing';
import { ContentfulArticlePage, ContentfulEcomCommon } from '../contentful';

interface ArticlePagesQueryProps {
  contentfulPageArticle: ContentfulArticlePage;
  contentfulEcomCommon: ContentfulEcomCommon;
}

type ArticlePagesType = PageProps<ArticlePagesQueryProps>;

const ArticlePages = ({ data }: ArticlePagesType) => {
  return (
    <>
      <Helmet title={data.contentfulPageArticle.title} />
      <Grid container spacing={4} sx={{ pt: 5 }}>
        <Grid item xs={12} sm={5}>
          {data.contentfulPageArticle.image ? (
            <GatsbyImage
              image={data.contentfulPageArticle.image.gatsbyImage}
              alt={data.contentfulPageArticle.title}
              style={{ maxHeight: 400, backgroundColor: '#fff' }}
              imgStyle={{ objectFit: 'contain' }}
            />
          ) : (
            <ImageMissing text={data.contentfulEcomCommon.imageMissingText} />
          )}
        </Grid>
        <Grid item xs={12} sm={7}>
          <ArticleInfo
            articlePage={data.contentfulPageArticle}
            ecomCommon={data.contentfulEcomCommon}
          />
        </Grid>
      </Grid>
    </>
  );
};

export const query = graphql`
  query ($slug: String!) {
    contentfulPageArticle(slug: { eq: $slug }) {
      ...ArticlePage
    }
    contentfulEcomCommon {
      ...EcomCommon
    }
  }
`;

export default ArticlePages;
